const settings = {
  api_url: '/api',
  socket_url: '/',      // socket url; any sub dir may not work! eg /api will not work
  languages: ['pl'],              // All available languages
  normalAdminModules: ['challenges', 'flags', 'challenge_categories', 'maps', 'users', 'sessions', 'logs', 'stacks'],

//  custom_icon_set: {
/*	  // beginners path
	  bp_1_white: {
      shape: 'image',
      image: '/images/customization/icons/bp/1_white.svg',
      size: 32
    },
    bp_2_white: {
      shape: 'image',
      image: '/images/customization/icons/bp/2_white.svg',
      size: 32
    },

    bp_3_yellow: {
      shape: 'image',
      image: '/images/customization/icons/bp/3_yellow.svg',
      size: 32
    },
    bp_4_yellow: {
      shape: 'image',
      image: '/images/customization/icons/bp/4_yellow.svg',
      size: 32
    },

    bp_5_orange: {
      shape: 'image',
      image: '/images/customization/icons/bp/5_orange.svg',
      size: 32
    },
    bp_6_orange: {
      shape: 'image',
      image: '/images/customization/icons/bp/6_orange.svg',
      size: 32
    },

    bp_7_green: {
      shape: 'image',
      image: '/images/customization/icons/bp/7_green.svg',
      size: 32
    },
    bp_8_green: {
      shape: 'image',
      image: '/images/customization/icons/bp/8_green.svg',
      size: 32
    },

    bp_9_blue: {
      shape: 'image',
      image: '/images/customization/icons/bp/9_blue.svg',
      size: 32
    },
    bp_10_blue: {
      shape: 'image',
      image: '/images/customization/icons/bp/10_blue.svg',
      size: 32
    },

    bp_11_purple: {
      shape: 'image',
      image: '/images/customization/icons/bp/11_purple.svg',
      size: 32
    },
    bp_12_purple: {
      shape: 'image',
      image: '/images/customization/icons/bp/12_purple.svg',
      size: 32
    },

    bp_13_red: {
      shape: 'image',
      image: '/images/customization/icons/bp/13_red.svg',
      size: 32
    },
    bp_14_red: {
      shape: 'image',
      image: '/images/customization/icons/bp/14_red.svg',
      size: 32
    },

    bp_15_brown: {
      shape: 'image',
      image: '/images/customization/icons/bp/15_brown.svg',
      size: 32
    },
    bp_16_brown: {
      shape: 'image',
      image: '/images/customization/icons/bp/16_brown.svg',
      size: 32
    },

    bp_17_gray: {
      shape: 'image',
      image: '/images/customization/icons/bp/17_gray.svg',
      size: 32
    },
    bp_18_gray: {
      shape: 'image',
      image: '/images/customization/icons/bp/18_gray.svg',
      size: 32
    },

    bp_19_black: {
      shape: 'image',
      image: '/images/customization/icons/bp/19_black.svg',
      size: 32
    },
    bp_20_black: {
      shape: 'image',
      image: '/images/customization/icons/bp/20_black.svg',
      size: 32
    },

    bp_belt_black: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_black.svg',
      size: 32
    },
    bp_belt_black_2: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_black_2.svg',
      size: 32
    },

    bp_belt_blue: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_blue.svg',
      size: 32
    },
    bp_belt_blue_2: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_blue_2.svg',
      size: 32
    },

    bp_belt_brown: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_brown.svg',
      size: 32
    },
    bp_belt_brown_2: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_brown_2.svg',
      size: 32
    },

    bp_belt_gray: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_gray.svg',
      size: 32
    },
    bp_belt_gray_2: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_gray_2.svg',
      size: 32
    },

    bp_belt_green: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_green.svg',
      size: 32
    },
    bp_belt_green_2: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_green_2.svg',
      size: 32
    },

    bp_belt_orange: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_orange.svg',
      size: 32
    },
    bp_belt_orange_2: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_orange_2.svg',
      size: 32
    },

    bp_belt_purple: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_purple.svg',
      size: 32
    },
    bp_belt_purple_2: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_purple_2.svg',
      size: 32
    },

    bp_belt_red: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_red.svg',
      size: 32
    },
    bp_belt_red_2: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_red_2.svg',
      size: 32
    },

    bp_belt_white: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_white.svg',
      size: 32
    },
    bp_belt_white_2: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_white_2.svg',
      size: 32
    },

    bp_belt_yellow: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_yellow.svg',
      size: 32
    },
    bp_belt_yellow_2: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_yellow_2.svg',
      size: 32
    },

		    bp_13_black: {
      shape: 'image',
      image: '/images/customization/icons/bp/13_black.svg',
      size: 32
    },
    bp_14_black: {
      shape: 'image',
      image: '/images/customization/icons/bp/14_black.svg',
      size: 32
    },
*/
/*    learn: {
      shape: 'image',
      image: '/images/customization/icons/bp/learn.svg',
      size: 32
    },
    pratice: {
      shape: 'image',
      image: '/images/customization/icons/bp/practice.svg',
      size: 32
    },
	// woc
    outside_task: {
      shape: 'image',
      image: '/images/customization/icons/action_ikonka_blue2.svg',
      size: 33
    },
    outside_task_blue: {
      shape: 'image',
      image: '/images/customization/icons/action_ikonka_blue.svg',
      size: 33
    },
    outside_task_blue2: {
      shape: 'image',
      image: '/images/customization/icons/action_ikonka_blue2.svg',
      size: 33
    },

    osint: {
      shape: 'image',
      image: '/images/customization/icons/osint_ikonka_blue2.svg',
      size: 33
    },
    osint_blue: {
      shape: 'image',
      image: '/images/customization/icons/osint_ikonka_blue.svg',
      size: 33
    },
    osint_blue2: {
      shape: 'image',
      image: '/images/customization/icons/osint_ikonka_blue2.svg',
      size: 33
    },

    osint_2: {
      shape: 'image',
      image: '/images/customization/icons/osint_ikonka_2_blue2.svg',
      size: 33
    },
    osint_2_blue: {
      shape: 'image',
      image: '/images/customization/icons/osint_ikonka_2_blue.svg',
      size: 33
    },
    osint_2_blue2: {
      shape: 'image',
      image: '/images/customization/icons/osint_ikonka_2_blue2.svg',
      size: 33
    },

    steganography: {
      shape: 'image',
      image: '/images/customization/icons/steganography_ikonka_blue2.svg',
      size: 33
    },
    steganography_blue: {
      shape: 'image',
      image: '/images/customization/icons/steganography_ikonka_blue.svg',
      size: 33
    },
    steganography_blue2: {
      shape: 'image',
      image: '/images/customization/icons/steganography_ikonka_blue2.svg',
      size: 33
    },

    web: {
      shape: 'image',
      image: '/images/customization/icons/web_ikonka_blue2.svg',
      size: 33
    },
    web_blue: {
      shape: 'image',
      image: '/images/customization/icons/web_ikonka_blue.svg',
      size: 33
    },
    web_blue2: {
      shape: 'image',
      image: '/images/customization/icons/web_ikonka_blue2.svg',
      size: 33
    },

    web_2: {
      shape: 'image',
      image: '/images/customization/icons/web_ikonka_2_blue2.svg',
      size: 33
    },
    web_2_blue: {
      shape: 'image',
      image: '/images/customization/icons/web_ikonka_2_blue.svg',
      size: 33
    },
    web_2_blue2: {
      shape: 'image',
      image: '/images/customization/icons/web_ikonka_2_blue2.svg',
      size: 33
    },

    forensic: {
      shape: 'image',
      image: '/images/customization/icons/forensic_ikonka_blue2.svg',
      size: 33
    },
    forensic_blue: {
      shape: 'image',
      image: '/images/customization/icons/forensic_ikonka_blue.svg',
      size: 33
    },
    forensic_blue2: {
      shape: 'image',
      image: '/images/customization/icons/forensic_ikonka_blue2.svg',
      size: 33
    },

    cryptography: {
      shape: 'image',
      image: '/images/customization/icons/cryptography_ikonka_blue2.svg',
      size: 33
    },
    cryptography_blue: {
      shape: 'image',
      image: '/images/customization/icons/cryptography_ikonka_blue.svg',
      size: 33
    },
    cryptography_blue2: {
      shape: 'image',
      image: '/images/customization/icons/cryptography_ikonka_blue2.svg',
      size: 33
    },

    front_end: {
      shape: 'image',
      image: '/images/customization/icons/front-end_ikonka_blue2.svg',
      size: 33
    },
    front_end_blue: {
      shape: 'image',
      image: '/images/customization/icons/front-end_ikonka_blue.svg',
      size: 33
    },
    front_end_blue2: {
      shape: 'image',
      image: '/images/customization/icons/front-end_ikonka_blue2.svg',
      size: 33
    },

    programming: {
      shape: 'image',
      image: '/images/customization/icons/programming_ikonka_blue2.svg',
      size: 33
    },
    programming_blue: {
      shape: 'image',
      image: '/images/customization/icons/programming_ikonka_blue.svg',
      size: 33
    },
    programming_blue2: {
      shape: 'image',
      image: '/images/customization/icons/programming_ikonka_blue2.svg',
      size: 33
    },

    reverse_eng: {
      shape: 'image',
      image: '/images/customization/icons/riverse_eng_ikonka_blue2.svg',
      size: 33
    },
    reverse_eng_blue: {
      shape: 'image',
      image: '/images/customization/icons/riverse_eng_ikonka_blue.svg',
      size: 33
    },
    reverse_eng_blue2: {
      shape: 'image',
      image: '/images/customization/icons/riverse_eng_ikonka_blue2.svg',
      size: 33
    },

    special_quest: {
      shape: 'image',
      image: '/images/customization/icons/special_quest_ikonka_blue2.svg',
      size: 33
    },
    special_quest_blue: {
      shape: 'image',
      image: '/images/customization/icons/special_quest_ikonka_blue.svg',
      size: 33
    },
    special_quest_blue2: {
      shape: 'image',
      image: '/images/customization/icons/special_quest_ikonka_blue2.svg',
      size: 33
    },
*/
    /*logo: {
      shape: 'image',
      // image: '/images/loader.svg',
      image: '/images/customization/logo.svg',
      size: 33
    },

    hidden: {
      shape: 'image',
      // image: '/images/loader.svg',
      image: '/images/customization/icons/ukryte_ikonka_blue2.svg',
      size: 33
    },
  },*/

  custom_attribute_set: {
    stars1: {
      shape: 'image',
      image: '/images/customization/icons/1stars.svg',
      size: 33
    },
    stars2: {
      shape: 'image',
      image: '/images/customization/icons/2stars.svg',
      size: 33
    },
    stars3: {
      shape: 'image',
      image: '/images/customization/icons/3stars.svg',
      size: 33
    },
  },

  custom_icon_set_groups_blacklist: [
    'outside_task_blue',
    'outside_task_blue2',
    'osint_2_blue',
    'osint_2_blue2',
    'osint_blue',
    'osint_blue2',
    'steganography_blue',
    'steganography_blue2',
    'web_2_blue',
    'web_2_blue2',
    'web_blue',
    'web_blue2',
    'forensic_blue',
    'forensic_blue2',
    'cryptography_blue',
    'cryptography_blue2',
    'front_end_blue',
    'front_end_blue2',
    'programming_blue',
    'programming_blue2',
    'reverse_eng_blue',
    'reverse_eng_blue2',
    'special_quest_blue',
    'special_quest_blue2',
    'hidden',
  ],

  ctf_custom_icons_done: { ctf_jeopardy: 'blue', hd_ctf: 'blue' },
  ctf_custom_icons_undone: { ctf_jeopardy: 'blue2', hd_ctf: 'blue2' },

  custom_map_colors: ['#ff0000', '#000000', '#EAE8FF', '#ffd700', '#0057b7', '#cf4e50'],

  disable_flags_in_ranking: false,

  app_title: 'Hackingdept DKWOC',
  app_footer: 'DOWÓDZTWO KOMPONENTU WOJSK OBRONY CYBERPRZESTRZENI\nLABORATORIUM CYBERBEZPIECZEŃSTWA ©2024',
  app_login_text: 'HackingDept DKWOC',
  // app_logo2: 'ctf-logo.png',
  app_login_title: 'HackingDept',
  app_login_subtitle: 'DKWOC',
  app_login_footer: 'DOWÓDZTWO KOMPONENTU WOJSK OBRONY CYBERPRZESTRZENI\nLABORATORIUM CYBERBEZPIECZEŃSTWA ©2024',
  app_logo: 'dkwoc_logo_pl.png',
  app_logo_menu: 'dkwoc_logo_pl.png',
  //app_loader: 'logo.svg',
  app_loader: 'dkwoc_logo_pl.svg',
  app_favicon: 'dkwoc_logo_pl.png',
  app_ranking_logo: 'dkwoc_logo_pl.svg',
  //app_ranking_logo: 'logo.svg',
  // app_map_bg: 'bg10.png',
  // app_logo_stack_title: 'ctf-logo.png',
  //map_default_font_size: 18,                 // Size of font on map; default value: 14


  default_lang: 'pl',                   // Default app language
  installation_type: 'cloud',           // installation type: [cloud, box]
  recruitment: false,                   // true if this is install for recruitment purposes
  ajax_refresh_rate: 0,                 // Wyrażony w sekundach, odstęp czasu pomiędzy pobieraniem danych widoku maszyn i scenariuszy
  registration: true,                  // Is registration form enabled
  password_reset: false,                // Is password reset form enabled
  instruction: false,                   // Show instruction button in top nav
  // stack_switch_state: 'poweroff',    // Action to make on previous stack when switching stack, if not set no prompt will show
  // stack_switch_default_change_state: false, // Make stack switch prompt state change checkbox checked for default; if false or not set will be not checked
  scenarios_blocking_enabled: false,    // Are scenario reservations enabled?
  overlord_enabled: true,
  stack_types: [                        // Stack types available for this install
    'box', 'cloud', 'ctf_lab', 'ctf', 'ctf_jeopardy', 'beginners_path'
  ],
  ca_certificates: [
    'hackingdept', 'hackgroup'
  ],
  demo_types: [
    'full', 'partial'
  ],
  roles: [                              // Roles available for set for users
    {'role': 1, 'name': 'admin'},
    {'role': 2, 'name': 'user'},
    {'role': 3, 'name': 'user_recruitment'},
    {'role': 4, 'name': 'user_ctf_ro'},
    {'role': 5, 'name': 'user_ranking_only'}
  ],
  networks: [                           // VPN Networks available for this install
    // {'network_name': 'hackgroup-dmz', 'title': 'HackGroup DMZ'},
    {'network_name': 'hackbank-lan', 'title': 'HackBank LAN'},
    // {'network_name': 'hackgroup-malwarelab', 'title': 'HackGroup MalwareLab'},
    // {'network_name': 'hackstock-lan', 'title': 'HackStock LAN'},
    // {'network_name': 'ctf-lab-network', 'title': 'CTF Lab Network'},
    {'network_name': 'attackers-network', 'title': 'Attacker\'s Network'},
    {'network_name': 'management-network', 'title': 'HD Management Network'},
    {'network_name': 'pentesters-network', 'title': 'Pentester\'s Network'}
  ]
}

const views = {
  sa:"fNOvREpuGbsjgG5EXYfFA7J0Ebb2G0Ua",
  ad:"RkGXhJ3L0XrqTd9JSGVtvdRZJ99A6isu",
  us:"iZeephooGaiquo3Quiesh0ooLoh5xeij",
  re:"be8oe6cae9Kaequ5Gahsiexoobeequey",
  ucr:"IFoiTAfaitANxvGy58Pb5J1iwoXmqfEH",
  ur:"pNnmus2eS7vNUueAHeMoRDaZg8VuJ8Gh"
}

export default settings;
export { views }
